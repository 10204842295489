import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { filter } from "rxjs";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import CustomSelect from "~/shared/components/customSelect";
import PageHeader from "~/shared/components/page-header.component";
import Loader from "~/shared/components/ui/customLoader";
import ErrorPage from "~/shared/components/ui/error-page";
import {
  DEVICE_LICENSE_DETAILS,
  ORGANIZATION_PROBES_API,
  UPDATE_DEVICE_ON_LICENSE,
  REMOVE_LICENSE,
} from "~/shared/constants/api";
import useSidebarStore from "~/store/sidebar.store";

interface License {
  id: string;
  isActive: boolean;
}

interface Probe {
  id: string;
  externalDeviceId: string;
  name: string;
  nickName: string;
  plan: string;
  createdAt: string;
  updatedAt: string;
  organization: {
    id: string;
    name: string;
  };
}

interface Organization {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

interface InactiveProbe {
  id: string;
  nickName: string;
  externalDeviceId: string;
  isActive: boolean;
  registrationStatus: string;
  organizationId: string;
  createdAt: string;
  updatedAt: string;
  organization: Organization[];
  licenses: License[];
}

export default function ProbeReassignLicense() {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const [probe, setProbe] = useState<Probe | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newDeviceId, setNewDeviceId] = useState("");
  const [externalId, setExternalDeviceId] = useState<string>("");
  const [subId, setSubId] = useState<string>("");
  const [licenseId, setLicenseId] = useState<string>("");
  const [inactiveprobes, setInactiveProbes] = useState<InactiveProbe[]>([]);
  const [orgId, setOrgId] = useState("");
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  useEffect(() => {
    const fetchProbeDetails = async () => {
      try {
        const probeResponse = await networkService.get<any>(
          `${DEVICE_LICENSE_DETAILS}/${deviceId}`
        );
        const probeData = probeResponse.data[0] || "";
        console.log(probeData);

        setSubId(probeData.subscription.id);
        setLicenseId(probeData.id);
        setProbe({
          id: probeData.device.id,
          externalDeviceId: probeData.device.externalDeviceId,
          name: probeData.device.name || "",
          nickName: probeData.device.nickName,
          plan: probeData.subscription.plan.name,
          createdAt: probeData.createdAt,
          updatedAt: probeData.updatedAt,
          organization: {
            id: probeData.subscription.organization.id,
            name: probeData.subscription.organization.name,
          },
        });
        setOrgId(probeData.subscription.organization.id);
        setExternalDeviceId(probeData.device.externalDeviceId);
      } catch (error) {
        setError("No license found on the device");
      } finally {
        setLoading(false);
      }
    };

    const fetchProbes = async () => {
      try {
        const response = await networkService.get<any>(
          `${ORGANIZATION_PROBES_API}/${orgId}`
        );

        // Assuming response.data.data is an array of probes

        const allProbes: InactiveProbe[] = response.data.results;

        // Filter out probes where the first license's isActive is false
        const inactiveProbes = allProbes.filter(
          (probe) => probe.licenses.length == 0
        );

        setInactiveProbes(inactiveProbes); // Set state with filtered probes
      } catch (error) {
        const err = error as IError;
        toast.error(err.message);
      }
    };

    fetchProbeDetails();
    if (orgId) {
      fetchProbes();
    }
  }, [deviceId, orgId]);

  const handleRemoveLicense = async () => {
    if (!probe) {
      toast.error("Probe data is missing");
      return;
    }
    try {
      // Remove the license from the device
      await networkService.put<any>(
        `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
        {
          deviceId: null,
        }
      );
      console.log("License removed from the device");

      toast.success("License removed successfully");
      navigate(`/probes/${probe.id}`); // Redirect to a success page or relevant page
    } catch (error) {
      const err = error as IError;
      toast.error(
        err.message ?? "An error occurred while removing the license"
      );
    }
  };
  const handleReassignLicense = async () => {
    if (!probe) {
      toast.error("Probe data is missing");
      return;
    }
    if (!externalId) {
      setError("No probe selected for reassignment");
      return;
    }
    try {
      // Update the device on license
      await networkService.put<any>(
        `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
        {
          deviceId: newDeviceId,
        }
      );
      console.log("License is reassigned to new probe");

      toast.success("License reassigned successfully");
      navigate(`/probes/${probe.id}`); // Redirect to a success page or relevant page
    } catch (error) {
      const err = error as IError;
      toast.error(
        err.message ?? "An error occurred while reassigning the license"
      );
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const addOneMonth = (dateString: string): string => {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + 1);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Probes" />

      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Probes &gt; Re-assign License
        </h3>
      </div>
      <div className="mt-4 border-t border-gray-100">
        <dl>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Probe ID
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {probe?.externalDeviceId}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Probe Alias
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {probe?.nickName}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Current Plan
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{probe?.plan}</span>
              {/* <a href="#" className="text-indigo-600 underline ml-4">Upgrade plan</a> */}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              License assigned
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{licenseId}</span>
              {/* <a href="#" className="text-indigo-600 underline ml-4">Re-assign license</a> */}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Creation Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/creation-date.svg"
                alt="CalenderIcon"
                width={22}
                height={22}
              />
              <span>{formatDate(probe!.createdAt)}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Expiry Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/updated-date.svg"
                alt="CalenderIcon"
                width={22}
                height={22}
              />
              <span>{formatDate(addOneMonth(probe!.createdAt))}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Select Probe to Reassign License
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {/* <select
                                className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm"
                                value={newDeviceId}
                                onChange={(e) => setNewDeviceId(e.target.value)}
                            >
                                <option value="">Select Probe</option>
                                {inactiveprobes.map((probe) => (
                                    <option key={probe.id} value={probe.id}>
                                        {probe.nickName}
                                    </option>
                                ))}
                            </select> */}
              <CustomSelect
                selectedValue={newDeviceId}
                onChangeHandler={(value) => setNewDeviceId(value)}
                placeholder="Select Probe">
                <option value="">Select Probe</option>
                {inactiveprobes.map((probe) => (
                  <option key={probe.id} value={probe.id}>
                    {probe.nickName}
                  </option>
                ))}
              </CustomSelect>
            </dd>
          </div>
        </dl>
        <div className="mt-12 flex flex-wrap justify-end gap-4">
          <button
            onClick={handleReassignLicense}
            className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk disabled:bg-pink-100 disabled:text-gray-500 disabled:cursor-not-allowed"
            disabled={inactiveprobes.length === 0}>
            Reassign License
          </button>
          <button
            onClick={handleRemoveLicense}
            className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-red-300 text-sm sm:text-base md:text-base font-space-grotesk">
            Remove License
          </button>
        </div>
      </div>
    </div>
  );
}
