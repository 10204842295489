import { ErrorMessage, Field, Form, Formik } from "formik";
import { InfoIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import Carousel from "~/shared/components/carousel";
import CustomModal from "~/shared/components/customModal";
import CustomSelect from "~/shared/components/customSelect";
import PageHeader from "~/shared/components/page-header.component";
import ErrorPage from "~/shared/components/ui/error-page";
import {
  DEVICE_LICENSE_DETAILS,
  GET_LICENSES_BY_ORG_ID,
  GET_MANUFACTURER,
  PROBE_DETAILS_API,
  REGISTER_DEVICE,
  UPDATE_DEVICE_ON_LICENSE,
} from "~/shared/constants/api";
import { validateNullishObjectFields } from "~/shared/utils/helper.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
interface ProbeData {
  device: {
    id: string;
    externalDeviceId: string;
    nickName: string;
    isActive: boolean;
    modelId: string;
    organization: {
      id: string;
      name: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    };
    registrationStatus: string;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
  };
  owner: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

const UpdateProbeDetails = () => {
  const [initialValues, setInitialValues] = useState({
    manufacturer: { name: "", id: "", models: [{ name: "", id: "" }] },
    model: { modelName: "", id: "" },
    externalDeviceId: "",
    nickName: "",
    planName: "N/A",
    licenseId: "N/A",
  });
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { orgId, deviceId } = useParams();
  const [searchParams] = useSearchParams();
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const licenseId = searchParams.get("licenseId");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const { user } = useAuthStore((state) => ({ user: state.user }));

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await networkService.get<any>(GET_MANUFACTURER);
        setManufacturers(response.data);
        return response.data;
      } catch (error) {
        const err = error as IError;
        toast.error(err.message);
      }
    };
    const fetchModelImages = async (modelId: string) => {
      try {
        const galleryResponse = await networkService.get<any>(
          `${GET_MANUFACTURER}/models/${modelId}/gallery`
        );

        const imagePromises = galleryResponse.data.gallery.map(
          async (img: any) => {
            const imageResponse = await networkService.get<Blob>(
              `${GET_MANUFACTURER}/models/${modelId}/gallery/${img.id}`,
              null,
              { responseType: "blob" }
            );
            if (!(imageResponse instanceof Blob)) {
              throw new Error("Response is not a Blob");
            }
            return URL.createObjectURL(imageResponse);
          }
        );
        setImages(await Promise.all(imagePromises));
      } catch (error) {
        const err = error as IError;
        console.error(`Error fetching images for model ${modelId}:`, err);
        toast.error(err.message);
        setImages([]);
      }
    };
    const fetchDevice = async (manufacturers) => {
      try {
        const response = await networkService.get<any>(
          `${REGISTER_DEVICE}/device/${deviceId}`
        );
        const licenseResponse = await networkService.get<any>(
          `${DEVICE_LICENSE_DETAILS}/${deviceId}`
        );
        const data: ProbeData = response.data;
        const licenseData = licenseResponse.data[0];

        const manufacturer = manufacturers.find((man) =>
          man.models.some((model) => model.id === data.device.modelId)
        );
        const model = manufacturer
          ? manufacturer.models.find(
              (model) => model.id === data.device.modelId
            )
          : { modelName: "", id: "" };

        setInitialValues({
          manufacturer: manufacturer
            ? {
                name: manufacturer.name,
                id: manufacturer.id,
                models: manufacturer.models,
              }
            : { name: "", id: "", models: [{ name: "", id: "" }] },
          model: model
            ? { modelName: model.modelName, id: model.id }
            : { modelName: "", id: "" },
          externalDeviceId: data.device.externalDeviceId || "",
          nickName: data.device.nickName || "",
          licenseId: licenseData?.id ?? "N/A",
          planName: licenseData?.subscription?.plan?.name ?? "N/A",
        });
        if (manufacturer) {
          setModels(manufacturer.models);
        }
        fetchModelImages(model.id);
      } catch (err) {
        console.error("Failed to fetch device details:", err);
      }
    };
    const fetchLicense = async () => {
      try {
        if (licenseId) {
          const licenseResponse = await networkService.get<any>(
            `${GET_LICENSES_BY_ORG_ID}/${user?.organizationId}`
          );
          const licenseData = licenseResponse.data.results.filter(
            (f) => f.id === licenseId
          )[0];
          setInitialValues((prev) => ({
            ...prev,
            licenseId: licenseData?.id ?? "N/A",
            planName: licenseData?.subscription?.plan?.name ?? "N/A",
          }));
        }
      } catch (error) {
        const err = error as IError;
        toast.error(err.message);
      }
    };

    fetchManufacturers().then((data) => {
      if (deviceId !== "new") {
        fetchDevice(data);
      } else {
        fetchLicense();
      }
    });
  }, [deviceId, orgId, licenseId, user?.organizationId]);

  const handleUpdate = async (values: {
    manufacturer: {
      name: string;
      id: string;
      models: {
        name: string;
        id: string;
      }[];
    };
    model: { id: string; modelName: string };
    externalDeviceId: string;
    nickName: string;
  }) => {
    console.log("values", values);
    if (
      !validateNullishObjectFields<{
        manufacturer: {
          name: string;
          id: string;
          models: {
            name: string;
            id: string;
          }[];
        };
        model: { id: string; modelName: string };
        externalDeviceId: string;
        nickName: string;
      }>(values)
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    try {
      const updateManufacturerResponse = await networkService.put<any>(
        `${GET_MANUFACTURER}/models/${values.model.id}`,
        {
          manufacturerId: values.manufacturer.id,
        }
      );
      if (updateManufacturerResponse) {
        toast.success("Manufacturer updated successfully");
        const probeUpdateResponse = await networkService.put<any>(
          `${PROBE_DETAILS_API}/${orgId}/${deviceId}`,
          {
            modelId: values.model.id,
            externalDeviceId: values.externalDeviceId,
            nickName: values.nickName,
          }
        );
        if (probeUpdateResponse) {
          toast.success("Probe details updated successfully");
          navigate("/licenses");
        }
      }
    } catch (error) {
      const err = error as IError;
      toast.error(err.message);
    }
  };

  const handleCreateNewProbe = async (values: {
    model: { id: string; modelName: string };
    externalDeviceId: string;
    nickName: string;
  }) => {
    try {
      if (!licenseId) {
        toast.error("Please select a license to assign to this probe");
        return;
      }
      const response = await networkService.post<any>(
        `${REGISTER_DEVICE}/${orgId}/register`,
        {
          nickName: values.nickName,
          externalDeviceId: values.externalDeviceId,
          modelId: values.model.id,
          isActive: true,
        }
      );
      toast.success("Probe Created Successfully");
      if (response) {
        const deviceId = response.data.id;
        const addLicenseResponse = await networkService.put<any>(
          `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
          {
            deviceId: deviceId,
          }
        );
        if (addLicenseResponse) {
          toast.success("License assigned successfully");
          navigate("/licenses");
        }
      }
    } catch (error) {
      const err = error as IError;
      toast.error(err.message);
    }
  };

  const validationSchema = Yup.object({
    manufacturer: Yup.object()
      .shape({
        name: Yup.string(),
        id: Yup.string(),
        models: Yup.array().of(
          Yup.object().shape({
            name: Yup.string(),
            id: Yup.string(),
          })
        ),
      })
      .required("Manufacturer is required"),
    model: Yup.object({
      id: Yup.string(),
      modelName: Yup.string(),
    }).required("Model is required"),
    externalDeviceId: Yup.string().required("Probe ID is required"),
    nickName: Yup.string().required("Probe Alias is required"),
  });

  if (error) return <ErrorPage error={error} />;

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Probes" />
      <CustomModal
        isOpen={openImageModal}
        onClose={() => {
          setOpenImageModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center">
          <Carousel images={images} interval={6000} />
        </div>
      </CustomModal>

      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Probes &gt; {deviceId === "new" ? "Add New Probe" : "Update Probe"}
        </h3>
      </div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={deviceId === "new" ? handleCreateNewProbe : handleUpdate}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="mt-4 space-y-4">
              {/* manufacturer info */}
              <div className="grid gap-x-6 grid-cols-2">
                <div>
                  <label
                    htmlFor="manufacturer"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Manufacturer
                  </label>
                  <CustomSelect
                    selectedValue={values.manufacturer.name}
                    onChangeHandler={(value: any) => {
                      setFieldValue("manufacturer", value);
                      setFieldValue("model", { modelName: "", id: "" });
                      setFieldValue("externalDeviceId", "");
                      setFieldValue("nickName", "");
                      setImages([]);
                      setModels(value.models);
                    }}>
                    {manufacturers.map((manufacturer: any) => (
                      <option key={manufacturer.id} value={manufacturer}>
                        {manufacturer.name}
                      </option>
                    ))}
                  </CustomSelect>
                  <ErrorMessage
                    name="manufacturer"
                    component="p"
                    className="text-red-500 text-xs mt-2"
                  />
                </div>

                <div>
                  <label
                    htmlFor="model"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Model
                  </label>
                  <CustomSelect
                    selectedValue={values.model.modelName}
                    onChangeHandler={(value: any) =>
                      setFieldValue("model", value)
                    }>
                    {models.map((model: any) => (
                      <option key={model.id} value={model}>
                        {model.modelName}
                      </option>
                    ))}
                  </CustomSelect>
                  <ErrorMessage
                    name="manufacturer"
                    component="p"
                    className="text-red-500 text-xs mt-2"
                  />
                </div>
              </div>
              {/* probe info */}
              <div className="grid gap-x-6 grid-cols-2">
                <div>
                  <div className="flex items-center">
                    <label
                      htmlFor="externalDeviceId"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Probe Serial Number
                    </label>
                    {deviceId !== "new" && images.length !== 0 && (
                      <button
                        type="button"
                        className="text-blue-500 flex items-center ml-2"
                        onClick={() => {
                          setOpenImageModal(true);
                        }}>
                        <InfoIcon size={16} />
                      </button>
                    )}
                  </div>

                  <Field
                    id="externalDeviceId"
                    name="externalDeviceId"
                    type="text"
                    // value={initialValues.externalDeviceId}
                    placeholder="Enter Probe Id"
                    className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                  />
                  <ErrorMessage
                    name="externalDeviceId"
                    component="p"
                    className="text-red-500 text-xs mt-2"
                  />
                </div>

                <div>
                  <label
                    htmlFor="nickName"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Probe Name
                  </label>
                  <Field
                    id="nickName"
                    name="nickName"
                    type="text"
                    // value={initialValues.nickName}
                    placeholder="Enter Probe Alias"
                    className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                  />
                  <ErrorMessage
                    name="nickName"
                    component="p"
                    className="text-red-500 text-xs mt-2"
                  />
                </div>
              </div>
              {/* license info */}
              <div className="grid gap-x-6 grid-cols-2">
                <div>
                  <label
                    htmlFor="planName"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Current Plan
                  </label>
                  <Field
                    id="planName"
                    name="planName"
                    type="text"
                    disabled
                    placeholder=""
                    className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400 disabled:cursor-not-allowed"
                  />
                  {/* <Link
                    to={`/probes/probe-plan-upgrade/${deviceId}`}
                    className="text-sm font-medium text-right text-linkcolor underline mt-2 block">
                    Upgrade Plan
                  </Link> */}
                </div>

                <div>
                  <label
                    htmlFor="licenseId"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    License assigned
                  </label>
                  <Field
                    id="licenseId"
                    name="licenseId"
                    type="text"
                    disabled
                    placeholder=" "
                    className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400 disabled:cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
            {/* Buttons */}
            <div className="mt-12 flex flex-wrap justify-end gap-4">
              <button
                type="button"
                onClick={() => navigate("/licenses")}
                className="py-2 px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
                Back
              </button>
              <button
                type="submit"
                className="py-2 px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
                disabled={isSubmitting}>
                {`${deviceId === "new" ? "Add New" : "Update"}`}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {error && <div className="mt-4 text-red-500">{error}</div>}
    </div>
  );
};

export default UpdateProbeDetails;
