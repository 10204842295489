import { toast } from "react-toastify";
import { UserRole } from "~/shared/config";
import { useEffect, useState } from "react";
import useAuthStore from "~/store/auth.store";
import { Invoice, TableColumn } from "~/interfaces/interfaces";
import useSidebarStore from "~/store/sidebar.store";
import { IError } from "~/interfaces/shared.interface";
import Dropdown from "~/shared/components/ui/dropdown";
import networkService from "~/services/network.service";
import PaymentTable from "~/shared/components/paymentTable";
import PageHeader from "~/shared/components/page-header.component";
import { capitalizeFirstLetter } from "~/shared/utils/helper.util";
import { CUSTOMER_DETAILS_API, PAYMENT_INVOICE_LIST, PAYMENT_INVOICE_LIST_USERS } from "~/shared/constants/api";
import GenericTableWithSorting from "~/shared/components/table/table";
import { Link } from "react-router-dom";
import NoProbe from "~/shared/components/ui/no-probe.component";

export default function Home() {
	const user = useAuthStore((state) => state.user);
	const [invoices, setInvoices] = useState<Invoice[]>([]);
	const [collapsed] = useSidebarStore((state) => [state.collapsed]);
	const [selectedOrg, setSelectedOrg] = useState("All organizations");
	const paymentStatusOptions = ["Show all payment","PENDING", "PAID"];
	const [paymentStatus,setPaymentStatus] = useState("Show all payment");
	const [membersSearch, setMembersSearch] = useState("");
	const [organizationOptions, setOrganizationOptions] = useState<string[]>([]);
	const [pageSize, setPageSize] = useState(7);
  	const [pageNumber, setPageNumber] = useState(1);
	const [totalLicenseResults, setTotalLicenseResults] = useState<{
		pageIndex: number;
		pageSize: number;
		totalItems: number;
		totalPages: number;
	  }>();

	  const adjustPageSize = () => {
		const offset = 120;
		const rowHeight = 100;
		const windowHeight = window.innerHeight;
		const numberOfRows = Math.floor((windowHeight - offset) / rowHeight);
		numberOfRows <= 3 ? setPageSize(5) : setPageSize(numberOfRows);
	  };

	  useEffect(() => {
		adjustPageSize();
		window.addEventListener("resize", adjustPageSize);
		return () => {
		  window.removeEventListener("resize", adjustPageSize);
		};
	  }, []);

	useEffect(()=>{
		const getOrganization = async () => {
			try{
				const response = await networkService.get<any>(CUSTOMER_DETAILS_API);
				setOrganizationOptions(() => ["All organizations", ...(response.data?.organizations?.map((org: any) => org.name) ?? [])]);
			}catch(error){
				console.log("Error fetching organization details :",error);
			}
		}
		if(user?.role === UserRole.Admin){
			getOrganization();
		}
	},[user]);

	useEffect(() => {
        if (!user) return;
        const fetchInvoiceList = async () => {
            try {
                let response;
                if (user.role === UserRole.Admin) {
                    response = await networkService.get<any>(`${PAYMENT_INVOICE_LIST}?pageIndex=${pageNumber - 1}&pageSize=${pageSize}`);
                } else if (user.organizationId) {
                    response = await networkService.get<any>(
                        `${PAYMENT_INVOICE_LIST_USERS}/${user.organizationId}?pageIndex=${pageNumber - 1}&pageSize=${pageSize}`,
                    );
                }

                if (response) {
                    setInvoices(response.data.results);
					setTotalLicenseResults(response.data.meta);
                }
            } catch (error) {
                const err = error as IError;
				toast.error(err.message);
            }
        };

        fetchInvoiceList();
    }, [user,pageSize, pageNumber]);

	const totalResults = totalLicenseResults?.totalItems || 0;
	const pageCount = Math.ceil(totalResults / pageSize);
	const handlePageChange = (newPage: number) => {
	  setPageNumber(newPage);
	};

	const colDefs: TableColumn[] = [
		{
		  field: "organizationName",
		  type: "string",
		  name: "Organization Name",
		  isSortable: true,
		  valueFormatter: (item) => {
			return (
			  <div className="min-w-[100px]">
				<p className="text-sm">{item.name}</p>
			  </div>
			);
		  },
		  comparator: (
			a: { name: string },
			b: { name: string },
			direction: "ascending" | "descending"
		  ) => {
			return direction === "ascending"
			  ? a.name.toLowerCase().localeCompare(b.name.toLowerCase())
			  : b.name.toLowerCase().localeCompare(a.name.toLowerCase());
		  },
		},
		{
		  field: "issueDate",
		  isSortable: true,
		  type: "string",
		  name: "Invoice date",
		  valueFormatter: (item) => {
			return (
			  <div className="min-w-[100px]">
				<p className="text-sm">{item.issueDate}</p>
			  </div>
			);
		  },
		  comparator: (
			a: { issueDate: string },
			b: { issueDate: string },
			direction: "ascending" | "descending"
		  ) => {
			const dateA = new Date(a.issueDate);
			const dateB = new Date(b.issueDate);
			return direction === "ascending"
			  ? dateA.getTime() - dateB.getTime()
			  : dateB.getTime() - dateA.getTime();
		  },
		},
		{
		  field: "status",
		  isSortable: true,
		  type: "string",
		  name: "Status",
		  valueFormatter: (item) => {
			return (
			  <div className="min-w-[100px]">
				<div className={`inline-flex flex-shrink-0 items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${item.status !== 'PAID' ? 'bg-Peach-100 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
                    {item.status}
                </div>
			  </div>
			);
		  },
		  comparator: (
			a: { status: string },
			b: { status: string },
			direction: "ascending" | "descending"
		  ) => {
			return direction === "ascending"
			  ? a.status.toLowerCase().localeCompare(b.status.toLowerCase())
			  : b.status.toLowerCase().localeCompare(a.status.toLowerCase());
		  },
		},
		{
		  field: "invoice",
		  isSortable: false,
		  type: "string",
		  name: "Invoice",
		  valueFormatter: (item) => {
			return (
			  <div className="min-w-[100px]">
				<Link className="text-indigo-600 inline-flex items-center underline" to={item.invoice}>
                    Download invoice
                </Link>
			  </div>
			);
		  },
		},
	  ];


	
	  const rowDefs: Record<string, any>[] = invoices.map((item) => ({
		organizationName: {
		  name: item.organization.name,
		},
		issueDate: {
		  issueDate: item.issueDate,
		},
		status: {
		  status: item.status,
		},
		invoice: {
		  invoice: item.fileUrl
		},
	  }));

	const filteredInvoices = invoices.filter((invoice) => {
		if (selectedOrg === "All organizations") {
			return true;
		}
		return invoice?.organization.name === selectedOrg;
	}).filter((invoice) => {
		if (paymentStatus === "Show all payment") {
			return true;
		}
		return invoice?.status === paymentStatus;	
	});

	return (
		<div className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
			<PageHeader title="Payment" />

			<div className="flex items-center justify-between mt-4">
				<div className="flex items-center">
					<img
						src="/filter-icon.svg"
						alt="Filter Icon"
						width={20}
						height={20}
						className="mr-2"
					/>
					<span className="text-neutral-900 font-medium font-space-grotesk">
						Filter by:
					</span>

					{user?.role === UserRole.Admin && (
						<div className="px-2">
							<Dropdown
								options={organizationOptions}
								onChange={setSelectedOrg}
								renderOption={(option) => option}
								selectedOption={selectedOrg}
							/>
						</div>
					)}

					{/* License Filter */}
					<div className="px-2">
						<Dropdown
							options={paymentStatusOptions}
							onChange={setPaymentStatus}
							renderOption={(option) => capitalizeFirstLetter(option.toLocaleLowerCase())}
							selectedOption={paymentStatus}
						/>
					</div>

				</div>
			</div>
			<div className="mt-2">
			{
				invoices.length === 0 ? 
					<NoProbe/> :
					<GenericTableWithSorting
					column={colDefs}
					row={rowDefs}
					searchField="organizationName.name"
					searchTerm={membersSearch}
					showPagination={true}
					totalResults={totalResults}
					pageSize={pageSize}
					pageCount={pageCount}
					resetToFirstPage={false}
					handlePageChange={handlePageChange}
					/>
			}
			</div>
		</div>
	);
}