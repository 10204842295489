import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import {
  CUSTOMER_DETAILS_API,
  GET_LICENSES_BY_ORG_ID,
  PROFILE_INFO_API,
} from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";

const DashBoardCards = ({
  setOpenModal,
}: {
  setOpenModal: (val: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { user, accessToken } = useAuthStore((state) => ({
    user: state.user,
    accessToken: state.accessToken,
  }));
  const [cards, setCards] = useState([
    {
      id: "1",
      title: "Marketing Link 1",
      status: "link 1",
    },
    {
      id: "2",
      title: "Marketing Link 2",
      status: "link 2",
    },
    {
      id: "3",
      title: "Marketing Link 3",
      status: "link 3",
    },
    {
      id: "4",
      title: "Marketing Link 4",
      status: "link 4",
    },
    {
      id: "5",
      title: "Marketing Link 5",
      status: "link 5",
    },
    {
      id: "6",
      title: "# Probes",
      status: "loading...",
    },
    {
      id: "7",
      title: "# Licenses",
      status: "loading...",
    },
  ]);

  const handleRequestLicense = async () => {
    if (!user) return;
    try {
      const requestLicenseResponse = await networkService.post<any>(
        `${CUSTOMER_DETAILS_API}/${user?.organizationId}/request-new-license`,
        {}
      );
      if (requestLicenseResponse) {
        setOpenModal(true);
      }
    } catch (error) {
      const err = error as IError;
      err && toast.error(err.message);
    }
  };

  useEffect(() => {
    if (!user) return;
    const getLicensesByOrganization = async () => {
      if (!user.organizationId) return;
      try {
        const response = await networkService.get<any>(
          `${GET_LICENSES_BY_ORG_ID}/${user.organizationId}`
        );
        return response.data.results;
      } catch (error) {
        const err = error as IError;
        err && toast.error(err.message);
        return null;
      }
    };
    const fetchUserInfoAndData = async () => {
      if (!user) return;
      try {
        // Fetch user info
        const userInfoResponse = await networkService.get<any>(
          `${PROFILE_INFO_API}/${user.userId}`
        );

        const userInfoData = userInfoResponse.data;
        const organizationId = userInfoData.organizations[0]?.organizationId;

        // Fetch organization probes if organizationId is available
        if (organizationId) {
          const licensesResponse = await getLicensesByOrganization();
          licensesResponse &&
            setCards([
              {
                id: "1",
                title: "Marketing Link 1",
                status: "link 1",
              },
              {
                id: "4",
                title: "# Licenses",
                status: licensesResponse?.length?.toString(),
              },
              {
                id: "2",
                title: "Marketing Link 2",
                status: "link 2",
              },
              {
                id: "3",
                title: "Marketing Link 3",
                status: "link 3",
              },
              {
                id: "6",
                title: "Marketing Link 6",
                status: "link 6",
              },

              {
                id: "7",
                title: "Marketing Link 4",
                status: "link 4",
              },
            ]);
        }
      } catch (error) {
        const err = error as IError;
        err?.message && toast.error(err.message);
      }
    };

    fetchUserInfoAndData();
  }, [user, accessToken]);

  return (
    <ul role="list" className="grid gap-6 sm:grid-cols-2 grid-cols-3">
      {cards.map((card) => (
        <li
          key={card.id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg shadow h-[155px]">
          <div className="p-6 bg-white">
            <div className="flex w-full items-center justify-between space-x-6">
              <div className="flex-1 truncate">
                <div className="flex items-center justify-between">
                  <h3 className="truncate text-2xl font-space-grotesk font-semibold text-black leading-normal">
                    {card.title}
                  </h3>
                  <span className="inline-flex flex-shrink-0 items-center px-1.5 py-0.5 text-xl font-bold hover:text-blue-700 transition-colors duration-100 cursor-pointer">
                    {card.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {card.id === "4" && (
            <div className="p-4 flex flex-wrap justify-end gap-4 bg-white">
              <button
                className="flex items-center gap-1 justify-between py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk hover:ring-pink-200 hover:ring-2"
                type="button"
                onClick={handleRequestLicense}>
                <span className="mr-2">
                  {/* Icon container */}
                  <img
                    src="redirect-arrow.svg"
                    alt="Delete"
                    width={20}
                    height={20}
                  />
                </span>
                <span>Request License</span>
              </button>
            </div>
          )}
          {card.id === "5" && (
            <div className="p-4 flex flex-wrap justify-end gap-4 bg-white">
              <button
                className="flex items-center gap-1 justify-between py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk hover:ring-pink-200 hover:ring-2"
                type="button"
                onClick={() => {
                  navigate(
                    `/probes/update-probe-details/${user.organizationId}/new`
                  );
                }}>
                <span className="mr-2">
                  <img
                    src="redirect-arrow.svg"
                    alt="Delete"
                    width={20}
                    height={20}
                  />
                </span>
                <span>Add a Probe</span>
              </button>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default DashBoardCards;
