import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import { CUSTOMER_DETAILS_API, GENERATE_REPORT } from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";

type Organization = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

type Device = {
  id: string;
  nickName: string;
  externalDeviceId: string;
  manufacturer: string;
  model: string;
  isActive: boolean;
  registrationStatus: string;
  createdAt: string;
  updatedAt: string;
};

type License = {
  id: string;
  deviceId: string;
  subscriptionId: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};

type Subscription = {
  id: string;
  nickName: string;
  planId: string;
  organizationId: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};

type ReportData = {
  organization: Organization;
  devices: Device[];
  licenses: License[];
  subscriptions: Subscription[];
};

export default function UpdateProbeDetails() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [reportData, setReportData] = useState<ReportData | null>(null);
  const [reportType, setReportType] = useState("Organization");
  const [error, setError] = useState<string | null>(null);
  const [organizationId, setOrganization] = useState("");
  const token = useAuthStore((state) => state.accessToken);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const user = useAuthStore((state) => state.user);
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  useEffect(() => {
    if (!user) return;

    if (user.userId && token) {
      const fetchCustomerList = async () => {
        try {
          const response = await networkService.get<any>(
            `${CUSTOMER_DETAILS_API}`
          );
          const data = response.data.results;
          if (data && data) {
            setOrganizations(data);
            setOrganization(data[0]?.id || "");
          } else {
            setOrganizations([]);
            setOrganization("");
          }
        } catch (error) {
          const err = error as IError;
          toast.error(err.message);
          console.error("Failed to fetch user info", error);
        }
      };

      fetchCustomerList();
    } else {
      console.log("User Id or token is missing");
    }
  }, [token, user]);

  const fetchReportData = async () => {
    if (!token) return; // Ensure token is available

    try {
      const response = await networkService.get<any>(GENERATE_REPORT, {
        orgId: organizationId,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      });
      setReportData(response);
      downloadExcel(response);
    } catch (err) {
      setError("Error fetching report data");
      console.error(err);
    }
  };

  const downloadExcel = (data: ReportData) => {
    const workbook = XLSX.utils.book_new();

    // Create worksheets for each data section
    const orgSheet = XLSX.utils.json_to_sheet([data.organization]);
    const devicesSheet = XLSX.utils.json_to_sheet(data.devices);
    const licensesSheet = XLSX.utils.json_to_sheet(data.licenses);
    const subscriptionsSheet = XLSX.utils.json_to_sheet(data.subscriptions);

    // Append sheets to workbook
    XLSX.utils.book_append_sheet(workbook, orgSheet, "Organization");
    XLSX.utils.book_append_sheet(workbook, devicesSheet, "Devices");
    XLSX.utils.book_append_sheet(workbook, licensesSheet, "Licenses");
    XLSX.utils.book_append_sheet(workbook, subscriptionsSheet, "Subscriptions");

    // Write the workbook and trigger a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Report.xlsx");
  };

  const handleGenerateReport = (e: React.FormEvent) => {
    e.preventDefault();
    if (startDate && endDate) {
      fetchReportData();
    } else {
      setError("Please select both start and end dates");
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setError(null);
    }
  }, [startDate, endDate]);

  if (!user) return <div></div>;

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Reports" />
      <form onSubmit={handleGenerateReport}>
        <div className="mt-12 space-y-4">
          <div className="flex flex-col">
            <label
              htmlFor="Organization"
              className="px-1 py-2 w-96 border-[#D0D0D6] placeholder-gray input-text focus:outline-none">
              Organization
            </label>
            <select
              id="Organization"
              name="Organization"
              className="px-1 py-2 w-96 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
              value={organizationId}
              onChange={(e) => setOrganization(e.target.value)}>
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex gap-x-6 w-full lg:flex-col">
            <div className="w-1/2 lg:w-full">
              <label
                htmlFor="Start_Date"
                className="block text-sm font-medium leading-6 text-gray-900">
                Start date
              </label>
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                selected={startDate}
                onChange={(date: Date | null) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className="px-1 py-2 w-96 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
              />
            </div>

            <div className="w-1/2 lg:w-full">
              <label
                htmlFor="End_Date"
                className="block text-sm font-medium leading-6 text-gray-900">
                End date
              </label>
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                selected={endDate}
                onChange={(date: Date | null) => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className="px-1 py-2 w-96 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
              />
            </div>
          </div>

          {error && <div className="text-red-500 text-sm">{error}</div>}
          <div className="mt-12 flex flex-wrap justify-end gap-4">
            <button
              type="submit"
              className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
              Generate Report
            </button>
          </div>
        </div>
      </form>

      {/* {reportData && (
                    <div className="mt-8">
                        <h2 className="text-xl font-bold mb-4">Report Data</h2>
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-lg font-bold">Organization</h3>
                                <p>ID: {reportData.organization.id}</p>
                                <p>Name: {reportData.organization.name}</p>
                                <p>Description: {reportData.organization.description}</p>
                                <p>Created At: {reportData.organization.createdAt}</p>
                                <p>Updated At: {reportData.organization.updatedAt}</p>
                            </div>

                            <div>
                                <h3 className="text-lg font-bold">Devices</h3>
                                <ul>
                                    {reportData.devices.map(device => (
                                        <li key={device.id}>
                                            {device.nickName} - {device.manufacturer} - {device.model}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div>
                                <h3 className="text-lg font-bold">Licenses</h3>
                                <ul>
                                    {reportData.licenses.map(license => (
                                        <li key={license.id}>
                                            Device ID: {license.deviceId} - Subscription ID: {license.subscriptionId}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div>
                                <h3 className="text-lg font-bold">Subscriptions</h3>
                                <ul>
                                    {reportData.subscriptions.map(subscription => (
                                        <li key={subscription.id}>
                                            NickName: {subscription.nickName} - Plan ID: {subscription.planId}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )} */}
    </div>
  );
}
