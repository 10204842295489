import React from "react";
import { IntlProvider } from "react-intl";
import { Outlet } from "react-router-dom";
import { DEFAULT_LOCALE, messages } from "../i18n";
import useLangStore from "../store/lang.store";
import ToastWrapper from "~/shared/components/toast-wrapper.component";
import { useMatomo } from "@keiko-app/react-matomo";

function App() {
  const lang = useLangStore((state) => state.value);
  const { tracker } = useMatomo();
  const locale = lang;

  tracker.trackPageView();

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages[locale]}>
      <Outlet />
      <ToastWrapper />
    </IntlProvider>
  );
}

export default App;
