import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import AppRouter from "./app/app-router.component.tsx";
import { MatomoProvider, MatomoProviderConfig } from "@keiko-app/react-matomo";

import "nprogress/nprogress.css";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import { MATOMO_SITE_ID, MATOMO_URL } from "./shared/constants/api.tsx";

const config: MatomoProviderConfig = {
  trackerBaseUrl: MATOMO_URL,
  siteId: MATOMO_SITE_ID,
};

createRoot(document.getElementById("root")!).render(
  <MatomoProvider config={config}>
    <AppRouter />
  </MatomoProvider>
);
