import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import Loader from "~/shared/components/ui/customLoader";
import ErrorPage from "~/shared/components/ui/error-page";
import { DEVICE_DETAILS_API, GET_MANUFACTURER } from "~/shared/constants/api";
import { formatDate } from "~/shared/utils/helper.util";
import useSidebarStore from "~/store/sidebar.store";
import { InfoIcon } from "lucide-react";
import CustomModal from "~/shared/components/customModal";
import Carousel from "~/shared/components/carousel";

interface DeviceDetails {
  id: string;
  externalDeviceId: string;
  nickName: string;
  currentPlan: string;
  manufacturer: string;
  model: string;
  licenseId: string;
  creationDate: string;
  expiryDate: string;
  createdAt: string;
  licenseCreatedAt: string;
  licenseExpiryDate: string;
}

interface Probe {
  id: string;
  externalDeviceId: string;
  nickName: string;
  licenseId: string;
  model: string;
  plan: string;
  name: string;
  createdAt: string;
  expiryDate: string;
  manufacturer: string;
  organization: {
    id: string;
    name: string;
  };
  licenseCreatedAt: string;
  licenseExpiryDate: string;
}

export default function DeviceDetailsPage() {
  const { deviceId } = useParams(); // Get the device ID from the URL
  const [deviceDetails, setDeviceDetails] = useState<Probe | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    // const fetchModelImages = async (modelId) => {
    //   try {
    //     const galleryResponse = await networkService.get<any>(
    //       `${GET_MANUFACTURER}/models/${modelId}/gallery`
    //     );
    //     const imagePromises = galleryResponse.data.gallery.map(
    //       async (img: any) => {
    //         const imageResponse = await networkService.get<Blob>(
    //           `${GET_MANUFACTURER}/models/${modelId}/gallery/${img.id}`,
    //           null,
    //           { responseType: "blob" }
    //         );
    //         return {
    //           imageId: img.id,
    //           src: URL.createObjectURL(imageResponse),
    //         };
    //       }
    //     );
    //     setImages(await Promise.all(imagePromises));
    //   } catch (error) {
    //     const err = error as IError;
    //     console.error(`Error fetching images for model ${modelId}:`, err);
    //     toast.error(err.message);
    //     setImages([]);
    //   }
    // };
    const fetchModelImages = async (modelId: string) => {
      try {
        const galleryResponse = await networkService.get<any>(
          `${GET_MANUFACTURER}/models/${modelId}/gallery`
        );
        console.log("Gallery data:", galleryResponse.data);

        const imagePromises = galleryResponse.data.gallery.map(
          async (img: any) => {
            const imageResponse = await networkService.get<Blob>(
              `${GET_MANUFACTURER}/models/${modelId}/gallery/${img.id}`,
              null,
              { responseType: "blob" }
            );
            if (!(imageResponse instanceof Blob)) {
              throw new Error("Response is not a Blob");
            }
            return URL.createObjectURL(imageResponse);
          }
        );
        setImages(await Promise.all(imagePromises));
        console.log("Images:", images);
      } catch (error) {
        const err = error as IError;
        console.error(`Error fetching images for model ${modelId}:`, err);
        toast.error(err.message);
        setImages([]);
      }
    };

    const fetchDeviceDetails = async () => {
      if (!deviceId) {
        setError("No device ID provided.");
        setLoading(false);
        return;
      }

      try {
        const deviceResponse = await networkService.get<any>(
          `${DEVICE_DETAILS_API}/${deviceId}`
        );
        const deviceData = deviceResponse.data.device;
        if (deviceData) {
          // Fallback to probe data
          const probe: Probe = {
            id: deviceData.id || "N/A",
            externalDeviceId: deviceData.externalDeviceId || "N/A",
            nickName: deviceData.nickName || "N/A",
            licenseId: deviceData.licenses?.[0]?.id || "N/A",
            model: deviceData.model.modelName || "N/A",
            name: deviceData.name || "N/A",
            plan: deviceData.licenses?.[0]?.subscription.plan.name || "N/A",
            createdAt: deviceData.createdAt || "N/A",
            expiryDate: deviceData.expiryDate || "N/A",
            manufacturer: deviceData.model.manufacturer.name || "N/A",
            organization: {
              id: deviceData.organization?.id || "N/A",
              name: deviceData.organization?.name || "N/A",
            },
            licenseCreatedAt: deviceData.licenses?.[0]?.createdAt || "N/A",
            licenseExpiryDate: deviceData.licenses?.[0]?.expiryDate || "N/A",
          };
          setDeviceDetails(probe);
          fetchModelImages(deviceData.modelId);
        }
      } catch (error) {
        const err = error as IError;
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDeviceDetails();
  }, [deviceId]);

  const handleReassignLicense = (e: any) => {
    if (
      deviceDetails &&
      deviceDetails.licenseId &&
      deviceDetails.licenseId !== "N/A"
    ) {
      navigate(`/probes/probe-reassign-license/${deviceId}`);
    } else {
      e.preventDefault();
      toast.error("No license assigned to re-assign.");
    }
  };

  if (loading) return <Loader />;
  if (error) return <ErrorPage error={error} />;

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Probe Details" />
      <CustomModal
        isOpen={openImageModal}
        onClose={() => {
          setOpenImageModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center">
          <Carousel images={images} interval={6000} />
        </div>
      </CustomModal>

      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Probe &gt; View details
        </h3>
      </div>
      <div className="mt-4 border-t border-gray-100">
        <dl>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Probe Serial Number
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {deviceDetails?.externalDeviceId}
            </dd>
            <button
              className="text-blue-500 flex items-center mt-2 ml-2"
              onClick={() => {
                setOpenImageModal(true);
              }}>
              <InfoIcon size={16} />
            </button>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Probe Name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {deviceDetails?.nickName || "N/A"}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Probe Manufacturer
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{deviceDetails?.manufacturer}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Probe Model
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{deviceDetails?.model}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Probe Creation Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/creation-date.svg"
                alt="CalenderIcon"
                width={22}
                height={22}
              />
              <span>
                {deviceDetails?.createdAt
                  ? formatDate(deviceDetails?.createdAt)
                  : "N/A"}
              </span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Current Plan
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{deviceDetails?.plan || "N/A"}</span>
              {/* <Link
                to={`/probes/probe-plan-upgrade/${deviceId}`}
                className="text-indigo-600 underline ml-4">
                Upgrade plan
              </Link> */}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              License assigned
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{deviceDetails?.licenseId}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              License Creation Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/creation-date.svg"
                alt="CalenderIcon"
                width={22}
                height={22}
              />
              <span>
                {deviceDetails?.licenseCreatedAt
                  ? formatDate(deviceDetails?.licenseCreatedAt)
                  : "N/A"}
              </span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              License Expiry Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/creation-date.svg"
                alt="CalenderIcon"
                width={22}
                height={22}
              />
              <span>
                {deviceDetails?.licenseExpiryDate
                  ? formatDate(deviceDetails.licenseExpiryDate)
                  : "N/A"}
              </span>
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-12 flex flex-wrap justify-end gap-4">
        <Link
          to={"/licenses"}
          className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
          Back
        </Link>
        {deviceDetails?.id && deviceDetails.organization?.id && (
          <Link
            to={`/probes/update-probe-details/${deviceDetails.organization.id}/${deviceDetails.id}`}
            className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-8 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
            Edit Probe Details
          </Link>
        )}
        {/* <Link
          to={`/probes/probe-reassign-license/${deviceId}`}
          onClick={handleReassignLicense}
          className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-8 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
          Reassign License
        </Link> */}
      </div>
    </div>
  );
}
