import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import Loader from "~/shared/components/ui/customLoader";
import { GET_MANUFACTURER } from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
import { LucideTrash2 } from "lucide-react";
import { IError } from "~/interfaces/shared.interface";
import { toast } from "react-toastify";

interface ManufacturerData {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  models: Model[];
  applications: Application[];
  image: string | null;
}

interface Model {
  id: string;
  modelName: string;
  instructions: string;
  image: string | null;
  images: { imageId: string; src: string }[] | null;
}

interface Application {
  id: string;
  applicationName: string;
  applicationDescription: string;
  manufacturerId: string;
}

export default function ManufacturerDetailsPage() {
  const { manufacturerId } = useParams();
  const [manufacturer, setManufacturer] = useState<ManufacturerData | null>(
    null
  );
  const [manufacturerImage, setManufacturerImage] = useState<string | null>(
    null
  );
  const [selectedManufacturerFile, setSelectedManufacturerFile] =
    useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentModelId, setCurrentModelId] = useState<string | null>(null);
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const [refresh, setRefresh] = useState<boolean>(false); // State to trigger re-render

  const token = useAuthStore((state) => state.accessToken);

  useEffect(() => {
    const fetchManufacturerDetails = async () => {
      try {
        if (!manufacturerId) {
          setError("Manufacturer ID is missing");
          setLoading(false);
          return;
        }

        console.log("Fetching manufacturer details...");
        const response = await networkService.get<any>(
          `${GET_MANUFACTURER}/${manufacturerId}`
        );

        const data = response.data;
        console.log("Fetched data:", data);

        // Fetch existing image for manufacturer
        if (data.image) {
          console.log("Fetching manufacturer image...");
          const imageResponse = await networkService.get<Blob>(
            `${GET_MANUFACTURER}/${manufacturerId}/image`,
            null,
            { responseType: "blob" }
          );

          if (imageResponse instanceof Blob) {
            const imageUrl = URL.createObjectURL(imageResponse);
            console.log("Fetched manufacturer image URL:", imageUrl);
            setManufacturerImage(imageUrl);
          } else {
            console.error("Expected Blob response but got:", imageResponse);
          }
        }

        // Fetch existing images for models gallery API
        if (data.models) {
          data.models = await Promise.all(
            data.models.map(async (model) => {
              try {
                const galleryResponse = await networkService.get<any>(
                  `${GET_MANUFACTURER}/models/${model.id}/gallery`
                );
                const imagePromises = galleryResponse.data.gallery.map(
                  async (img: any) => {
                    const imageResponse = await networkService.get<Blob>(
                      `${GET_MANUFACTURER}/models/${model.id}/gallery/${img.id}`,
                      null,
                      { responseType: "blob" }
                    );
                    return {
                      imageId: img.id,
                      src: URL.createObjectURL(imageResponse),
                    };
                  }
                );
                model.images = await Promise.all(imagePromises);
              } catch (error) {
                const err = error as IError;
                console.error(
                  `Error fetching images for model ${model.id}:`,
                  err
                );
                toast.error(err.message);
                model.images = [];
              }
              return model;
            })
          );
        }
        setManufacturer(data);
      } catch (err) {
        console.error("Error fetching manufacturer details:", err);
        setError("Failed to fetch manufacturer details");
      } finally {
        setLoading(false);
      }
    };

    fetchManufacturerDetails();
  }, [manufacturerId, token, refresh]); // Add refresh to dependency array

  const handleAddManufacturerImage = async () => {
    if (selectedManufacturerFile) {
      const formData = new FormData();
      formData.append("image", selectedManufacturerFile);

      try {
        const uploadResponse = await networkService.post<any>(
          `${GET_MANUFACTURER}/${manufacturerId}/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(
          "Manufacturer image uploaded successfully:",
          uploadResponse.data
        );
        const fetchResponse = await networkService.get<Blob>(
          `${GET_MANUFACTURER}/${manufacturerId}/image`,
          null,
          {
            responseType: "blob",
          }
        );
        if (fetchResponse instanceof Blob) {
          const imageUrl = URL.createObjectURL(fetchResponse);
          console.log("Fetched manufacturer image URL:", imageUrl);
          setManufacturerImage(imageUrl);
          const updatedResponse = await networkService.get<any>(
            `${GET_MANUFACTURER}/${manufacturerId}`
          );
          setManufacturer(updatedResponse.data);

          toast.success("Manufacturer image updated successfully!");
          setRefresh(!refresh); // Trigger re-render
        } else {
          throw new Error("Expected Blob, but got a different type");
        }
      } catch (err) {
        console.error("Error uploading manufacturer image:", err);
        toast.error("Failed to upload image");
      }
    } else {
      console.log("No file selected");
    }
  };

  const handleDeleteManufacturerImage = async () => {
    try {
      await networkService.delete<any>(
        `${GET_MANUFACTURER}/${manufacturerId}/image`
      );
      console.log("Manufacturer image deleted successfully");
      setManufacturerImage(null); // Clear the image URL from state
      setRefresh(!refresh); // Trigger re-render
    } catch (err) {
      console.error("Error deleting manufacturer image:", err);
    }
  };

  const handleDeleteModelImage = async (
    modelId: string,
    modelImageId: string
  ) => {
    try {
      await networkService.delete<any>(
        `${GET_MANUFACTURER}/models/${modelId}/gallery/${modelImageId}`
      );
      toast.success("Model image deleted successfully");
      // Update the model image in the state
      if (manufacturer) {
        const updatedManufacturer = {
          ...manufacturer,
          models: manufacturer.models.map((model) =>
            model.id === modelId ? { ...model, image: null } : model
          ),
        };
        setManufacturer(updatedManufacturer);
        setRefresh(!refresh); // Trigger re-render
      }
    } catch (error) {
      const err = error as IError;
      toast.error(err.message);
      console.error("Error deleting model image:", err);
    }
  };

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    modelId: string,
    files?: FileList
  ) => {
    const selectedFiles = files || (e.target as HTMLInputElement).files;
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      formData.append("modelId", modelId);
      formData.append("image", files[0]);
      setCurrentModelId(modelId);

      try {
        const uploadResponse = await networkService.post<any>(
          `${GET_MANUFACTURER}/models/${modelId}/gallery`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (uploadResponse) {
          toast.success("Images uploaded successfully");
        }

        // Fetch the updated images
        const fetchResponse = await networkService.get<any>(
          `${GET_MANUFACTURER}/models/${modelId}/gallery`
        );

        if (manufacturer?.models) {
          const updatedModels = manufacturer.models.map((model) =>
            model.id === currentModelId
              ? { ...model, images: fetchResponse.data.gallery } // Update the target model
              : model
          );

          setManufacturer({ ...manufacturer, models: updatedModels });
          setRefresh(!refresh); // Trigger re-render
        } else {
          console.error("Manufacturer or models not found");
        }
      } catch (error) {
        const err = error as IError;
        toast.error(err.message);
        console.error("Error uploading images:", err);
      }
    } else {
      console.log("No files selected");
    }
  };

  if (loading) return <Loader />;
  if (error) return <p>{error}</p>;

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Manufacturer Details" />

      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Device Manufacturer &gt; View details
        </h3>
      </div>
      <dl className="mt-8">
        <div className="py-2 flex items-start">
          <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
            Manufacturer Name
          </dt>
          <dd className="text-sm leading-6 text-Neutral-900 font-space-grotesk">
            {manufacturer?.name || "N/A"}
          </dd>
        </div>
        <div className="py-2 flex items-start">
          <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
            Manufacturer Image
          </dt>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-24 h-24">
              {manufacturerImage ? (
                <img
                  src={manufacturerImage}
                  alt={manufacturer?.name || "Manufacturer Image"}
                  width={200}
                  height={200}
                  className="rounded-md"
                />
              ) : (
                <p>No image available</p>
              )}
            </div>
            <div className="ml-4">
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  setSelectedManufacturerFile(
                    e.target.files ? e.target.files[0] : null
                  )
                }
                className="py-2 px-2 mt-0 mr-1 rounded-full border border-[#AFAFE7] text-xs  font-space-grotesk cursor-pointer"
              />
              <button
                onClick={handleAddManufacturerImage}
                className="py-2.5 px-2 mt-1 rounded-full border border-[#AFAFE7] text-xs font-space-grotesk cursor-pointer">
                Update & Refresh
              </button>
              {manufacturerImage && (
                <button
                  onClick={() => handleDeleteManufacturerImage()}
                  className="py-2.5 px-2 ml-1 mt-0  rounded-full border border-[#AFAFE7] text-xs font-space-grotesk cursor-pointer">
                  Delete Image
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="py-2 flex items-start">
          <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
            Models
          </dt>
          <div className="flex flex-col space-y-4 w-full">
            {manufacturer?.models.map((model) => (
              <div
                key={model.id}
                className="flex items-start flex-col space-y-4 border-b pb-2 last:border-0">
                <div className="flex items-center">
                  <div className="ml-4">
                    <h4 className="text-sm font-semibold leading-6 text-Neutral-900 font-space-grotesk">
                      {model.modelName}
                    </h4>
                    <p className="text-sm text-Neutral-900 font-space-grotesk">
                      {model.instructions}
                    </p>
                  </div>
                  {/* Image Preview Section */}
                  {model.images?.length > 0 && (
                    <div className="flex space-x-2 overflow-x-auto py-2 ml-10">
                      {model.images.map((image) => (
                        <div
                          key={image.imageId}
                          className="relative w-24 h-24 flex-shrink-0">
                          <img
                            src={image.src}
                            alt={`model-${image.imageId}`}
                            className="w-full h-full object-cover rounded-md"
                          />
                          <button
                            onClick={() =>
                              handleDeleteModelImage(model.id, image.imageId)
                            }
                            className="absolute top-1 right-1 bg-red-400 rounded-full p-1">
                            <LucideTrash2 size={16} />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {/* Drag and Drop Area */}
                <div
                  className="w-1/2 h-60 border-dashed border-2 border-gray-300 rounded-md flex items-center justify-center cursor-pointer"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    if (
                      e.dataTransfer.files &&
                      e.dataTransfer.files.length > 0
                    ) {
                      handleFileUpload(e, model.id, e.dataTransfer.files);
                    }
                  }}>
                  <label
                    htmlFor={`upload-${model.id}`}
                    className="text-center text-sm text-gray-500">
                    Drag & Drop or{" "}
                    <span className="text-indigo-600">Browse</span> to upload an
                    image
                  </label>
                  <input
                    id={`upload-${model.id}`}
                    type="file"
                    multiple
                    onChange={(e) =>
                      handleFileUpload(e, model.id, e.target.files)
                    }
                    className="hidden"
                  />
                </div>
                <div className="flex space-x-2 w-1/2">
                  {/* Add More Images Button */}
                  <button
                    onClick={() =>
                      document.getElementById(`upload-${model.id}`).click()
                    }
                    className="py-2 px-4 border border-blue-400 text-white rounded-full font-space-grotesk cursor-pointer hover:bg-blue-100 w-full">
                    Add More Images
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="py-2 flex items-start">
          <dt className="text-sm mt-8 font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
            Applications
          </dt>
          <div className="mt-8 flex flex-col space-y-4">
            {manufacturer?.applications.map((app) => (
              <div key={app.id} className="flex items-start">
                <div className="">
                  <h4 className="text-sm font-semibold leading-6 text-Neutral-900 font-space-grotesk">
                    {app.applicationName}
                  </h4>
                  <p className="text-sm text-Neutral-900 font-space-grotesk">
                    {app.applicationDescription}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </dl>
      <div className="mt-12 flex flex-wrap justify-end gap-4 ">
        <Link
          to={"/manufacturer"}
          className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
          Back
        </Link>
      </div>
    </div>
  );
}
