import { CheckCircle } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TableColumn } from "~/interfaces/interfaces";
import { IError } from "~/interfaces/shared.interface";
import { networkService } from "~/services";
import CustomModal from "~/shared/components/customModal";
import CustomSelect from "~/shared/components/customSelect";
import { License } from "~/shared/components/licensesTable";
import PageHeader from "~/shared/components/page-header.component";
import GenericTableWithSorting from "~/shared/components/table/table";
import Dropdown from "~/shared/components/ui/dropdown";
import NoProbe from "~/shared/components/ui/no-probe.component";
import { STATUS_COLOR, UserRole } from "~/shared/config";
import {
  CUSTOMER_DETAILS_API,
  DEVICE_REGISTRATION_STATUS,
  GET_ALL_AVAILABLE_LICENSES,
  GET_LICENSES_BY_ORG_ID,
  PROBE_DETAILS_API,
  UPDATE_DEVICE_ON_LICENSE,
} from "~/shared/constants/api";
import { formatDate } from "~/shared/utils/helper.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";

const Licenses: React.FC = () => {
  const [selectedOrg, setSelectedOrg] = useState({
    name: "All Organizations",
    id: null,
  });
  const [licenseStatusFilter, setLicenseStatusFilter] =
    useState("All Licenses");
  const [membersSearch, setMembersSearch] = useState("");
  const navigate = useNavigate();
  const [organizationOptions, setOrganizationOptions] = useState<
    { name: string; id: string | null }[]
  >([]);
  const [licenses, setLicenses] = useState<License[]>([]);
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const licenseOptions = [
    "All Licenses",
    "Active Licenses",
    "Inactive Licenses",
  ];
  const [probes, setProbes] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(7);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLicenseResults, setTotalLicenseResults] = useState<{
    pageIndex: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  }>();
  const { user, accessToken } = useAuthStore((state) => ({
    user: state.user,
    accessToken: state.accessToken,
  }));

  const adjustPageSize = () => {
    const offset = 120;
    const rowHeight = 100;
    const windowHeight = window.innerHeight;
    const numberOfRows = Math.floor((windowHeight - offset) / rowHeight);
    numberOfRows <= 3 ? setPageSize(5) : setPageSize(numberOfRows);
  };

  const handleRequestLicense = async () => {
    if (!user) return;
    try {
      const requestLicenseResponse = await networkService.post<any>(
        `${CUSTOMER_DETAILS_API}/${user.organizationId}/request-new-license`,
        {}
      );
      if (requestLicenseResponse) {
        setOpenLicenseModal(true);
      }
    } catch (error) {
      const err = error as IError;
      err && toast.error(err.message);
    }
  };

  useEffect(() => {
    adjustPageSize();
    window.addEventListener("resize", adjustPageSize);
    return () => {
      window.removeEventListener("resize", adjustPageSize);
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    const getOrganization = async () => {
      try {
        const response = await networkService.get<any>(CUSTOMER_DETAILS_API);
        setOrganizationOptions(() => [
          { name: "All Organizations", id: null },
          ...(response.data.results?.map((org: any) => ({
            name: org.name,
            id: org.id,
          })) ?? []),
        ]);
      } catch (error) {
        const err = error as IError;
        err && toast.error(err.message);
      }
    };
    user?.role === UserRole.Admin && getOrganization();
  }, [user]);

  useEffect(() => {
    const getAllLicenses = async () => {
      try {
        const response = await networkService.get<any>(
          `${GET_ALL_AVAILABLE_LICENSES}?pageIndex=${pageNumber - 1}&pageSize=${pageSize}${licenseStatusFilter === "All Licenses" ? "" : `&isActive=${licenseStatusFilter === "Active Licenses"}`}${selectedOrg.id === null ? "" : `&organizationId=${selectedOrg.id}`}`
        );
        setLicenses(response.data.results);
        setTotalLicenseResults(response.data.meta);
      } catch (error) {
        const err = error as IError;
        err && toast.error(err.message);
      }
    };

    const getLicensesByOrganization = async () => {
      if (!user) return;
      try {
        const response = await networkService.get<any>(
          `${GET_LICENSES_BY_ORG_ID}/${user?.organizationId}?pageIndex=${pageNumber - 1}&pageSize=${pageSize}${licenseStatusFilter === "All Licenses" ? "" : `&isActive=${licenseStatusFilter === "Active Licenses"}`}`
        );
        setLicenses(response.data.results);
        setTotalLicenseResults(response.data.meta);
      } catch (error) {
        const err = error as IError;
        err && toast.error(err.message);
      }
    };

    if (user?.role === UserRole.Admin) {
      getAllLicenses();
    } else {
      getLicensesByOrganization();
    }
  }, [user, pageNumber, pageSize, licenseStatusFilter, selectedOrg]);

  const handleStatusChange = async (
    probeId: string,
    newStatus: string,
    organizationId: string
  ) => {
    if (!accessToken) {
      console.error("No token found");
      return;
    }
    try {
      await networkService.put<any>(
        `${DEVICE_REGISTRATION_STATUS}/${organizationId}/${probeId}/registration-status`,
        { registerStatus: newStatus }
      );
      toast.success("Registration status changed");
      setLicenses((prevLicenses) =>
        prevLicenses.map((license) =>
          license.device?.id === probeId
            ? {
                ...license,
                device: {
                  ...license.device,
                  registrationStatus: newStatus,
                },
              }
            : license
        )
      );
    } catch (error) {
      const err = error as IError;
      toast.error(err.message ?? "Something went wrong");
    }
  };
  useEffect(() => {
    if (!user) return;
    const fetchProbes = async () => {
      try {
        const probesResponse = await networkService.get<any>(
          `${PROBE_DETAILS_API}${user?.role === UserRole.Admin ? "" : `/organization/${user?.organizationId}`}`
        );
        const probesData = probesResponse.data.results;
        setProbes(probesData);
      } catch (error) {
        const err = error as IError;
        toast.error(err.message);
      }
    };

    fetchProbes();
  }, [user, licenses]);

  const onProbeSelect = async (id: string, licenseId: string) => {
    try {
      const res = await networkService.put<any>(
        `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
        {
          deviceId: id,
        }
      );
      if (res) {
        toast.success("Probe added successfully");
        navigate("/probes");
      }
    } catch (error) {
      const err = error as IError;
      toast.error(err.message);
    }
  };

  const totalResults = totalLicenseResults?.totalItems || 0;
  const pageCount = Math.ceil(totalResults / pageSize);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  const colDefs: TableColumn[] = [
    {
      field: "licenseId",
      type: "string",
      name: "License Id",
      isSortable: false,
      valueFormatter: (item) => {
        return (
          <div className="min-w-[120px] items-center justify-center flex">
            <p className="text-sm max-w-[80px] truncate">{item.id}</p>
          </div>
        );
      },
    },
    {
      field: "status",
      isSortable: true,
      type: "string",
      name: "Status",
      valueFormatter: (item) => {
        return (
          <div
            className={`inline-flex flex-shrink-0 items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${!item?.isActive ? "bg-Peach-100 text-red-700 ring-red-600/20" : "bg-green-50 text-green-700 ring-green-600/20"}`}>
            {item?.isActive ? "Active" : "Inactive"}
          </div>
        );
      },
      comparator: (
        a: { isActive: boolean },
        b: { isActive: boolean },
        direction: "ascending" | "descending"
      ) => {
        return direction === "ascending"
          ? Number(a.isActive) - Number(b.isActive)
          : Number(b.isActive) - Number(a.isActive);
      },
    },
    // {
    //   field: "createdAt",
    //   isSortable: true,
    //   type: "string",
    //   name: "Created At",
    //   valueFormatter: (item) => {
    //     return (
    //       <div className="min-w-[100px]">
    //         <p className="text-sm">
    //           {item?.createdAt ? formatDate(item.createdAt) : "N/A"}
    //         </p>
    //       </div>
    //     );
    //   },
    //   comparator: (
    //     a: { createdAt: string },
    //     b: { createdAt: string },
    //     direction: "ascending" | "descending"
    //   ) => {
    //     const dateA = new Date(a.createdAt);
    //     const dateB = new Date(b.createdAt);
    //     return direction === "ascending"
    //       ? dateA.getTime() - dateB.getTime()
    //       : dateB.getTime() - dateA.getTime();
    //   },
    // },
    ...(user?.role === UserRole.Admin
      ? [
          {
            field: "organization",
            isSortable: true,
            type: "string",
            name: "Organization",
            valueFormatter: (item) => {
              return (
                <div className="min-w-[100px]">
                  <p className="text-sm">{item.name}</p>
                </div>
              );
            },
            comparator: (
              a: { name: string },
              b: { name: string },
              direction: "ascending" | "descending"
            ) => {
              return direction === "ascending"
                ? a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                : b.name.toLowerCase().localeCompare(a.name.toLowerCase());
            },
          },
        ]
      : []),
    {
      field: "expiryDate",
      isSortable: true,
      type: "string",
      name: "Expiry Date (mm/dd/yy)",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm ml-10">
              {item.expiryDate ? formatDate(item.expiryDate) : "N/A"}
            </p>
          </div>
        );
      },
      comparator: (
        a: { expiryDate: string },
        b: { expiryDate: string },
        direction: "ascending" | "descending"
      ) => {
        const dateA = new Date(a.expiryDate);
        const dateB = new Date(b.expiryDate);
        return direction === "ascending"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      },
    },
    {
      field: "probe",
      isSortable: false,
      type: "string",
      name: "Probe ",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[120px]">
            {user?.role !== UserRole.Admin ? (
              item.device ? (
                item?.device?.nickName
              ) : probes.length > 0 ? (
                <div className="w-[130px] -ml-5">
                  <CustomSelect
                    onChangeHandler={(value) => onProbeSelect(value, item.id)}
                    placeholder="Probe"
                    selectedValue={null}
                    className="block w-full border px-1 border-gray-300 rounded-md shadow-sm text-sm">
                    {probes.map((probe) => (
                      <option key={probe.id} value={probe.id}>
                        {probe.nickName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
              ) : (
                <div className="w-[130px] text-center">
                  {"No Probe Assigned"}
                </div>
              )
            ) : item.device ? (
              <div className="text-center">{item?.device?.nickName}</div>
            ) : (
              <div className="text-center">{"No Probe Assigned"}</div>
            )}
          </div>
        );
      },
    },
    ...(user?.role === UserRole.Admin
      ? [
          {
            field: "registrationStatus",
            isSortable: false,
            type: "string",
            name: "Probe Registration Status",
            valueFormatter: (item) => {
              return item.id ? (
                <div className="min-w-[120px]">
                  <CustomSelect
                    selectedValue={item.registrationStatus}
                    onChangeHandler={(value) => {
                      handleStatusChange(item.id, value, item.orgId);
                    }}
                    placeholder="Select status"
                    className={`block w-full border px-1 border-gray-300 rounded-md shadow-sm text-sm ${STATUS_COLOR[item.registrationStatus as keyof typeof STATUS_COLOR]}`}>
                    <option value="REJECTED">Rejected</option>
                    <option value="PENDING">Pending</option>
                    <option value="APPROVED">Approved</option>
                  </CustomSelect>
                </div>
              ) : (
                <div className="min-w-[120px] text-center">{"--"}</div>
              );
            },
          },
        ]
      : []),
    {
      field: "probeId",
      isSortable: false,
      type: "string",
      name: "Probe Serial Number",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm text-center">{item.id ?? "--"}</p>
          </div>
        );
      },
    },
    {
      field: "model",
      isSortable: false,
      type: "string",
      name: "Model",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm text-center">{item.modelNo ?? "--"}</p>
          </div>
        );
      },
    },
    // {
    //   field: "manufacturer",
    //   isSortable: false,
    //   type: "string",
    //   name: "Manufacturer",
    //   valueFormatter: (item) => {
    //     return (
    //       <div className="min-w-[100px]">
    //         <p className="text-sm text-center">{item.manufacturer ?? "--"}</p>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      isSortable: false,
      type: "string",
      name: "Probe Actions",
      valueFormatter: (item) => {
        return item.id ? (
          <div className="flex min-w-[120px] items-center justify-center">
            <Link
              to={`/probes/${item.id}`}
              className="text-indigo-600 hover:text-indigo-900">
              View details
            </Link>
            <Link
              to={`/probes/update-probe-details/${item.organization}/${item.id}`}>
              <img
                src="/edit.svg"
                alt="edit"
                className="ml-2 h-6 w-6"
                width={20}
                height={20}
              />
            </Link>
          </div>
        ) : user?.role !== UserRole.Admin ? (
          <div>
            <button
              className="text-center py-2 px-6 rounded-full bg-gray-900 text-white-a700 text-base font-space-grotesk"
              onClick={() =>
                navigate(
                  `/probes/update-probe-details/${user.organizationId}/new?licenseId=${item.licenseId}`
                )
              }>
              Add a Probe
            </button>
          </div>
        ) : (
          <div className="text-center">{"--"}</div>
        );
      },
    },
  ];

  const rowDefs: Record<string, any>[] = licenses.map((item) => ({
    licenseId: {
      id: item.id,
    },
    createdAt: {
      createdAt: item.createdAt,
    },
    organization: {
      name: item.subscription.organization.name,
    },
    expiryDate: {
      expiryDate: item.expiryDate,
    },
    status: {
      isActive: item.isActive,
    },
    registrationStatus: {
      id: item?.device?.id,
      orgId: probes.find((probe) => probe.id === item?.device?.id)?.organization
        .id,
      registrationStatus: probes.find((probe) => probe.id === item?.device?.id)
        ?.registrationStatus,
    },
    probe: {
      device: item.device,
      id: item.id,
    },
    probeId: {
      id: item.device?.externalDeviceId,
    },
    model: {
      modelNo: probes.find((probe) => probe.id === item?.device?.id)?.model
        .modelName,
    },
    manufacturer: {
      manufacturer: probes.find((probe) => probe.id === item?.device?.id)?.model
        ?.manufacturer?.name,
    },
    actions: {
      licenseId: item.id,
      id: probes.find((probe) => probe.id === item?.device?.id)?.id,
      organization: probes.find((probe) => probe.id === item?.device?.id)
        ?.organization.id,
    },
  }));

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <CustomModal
        isOpen={openLicenseModal}
        onClose={() => {
          setOpenLicenseModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center">
          {/* icon */}
          <div className="rounded-full p-2 bg-confirm_green_light">
            <div className="rounded-full p-2 bg-confirm_green_dark bg-opacity-20">
              <CheckCircle className="w-7 h-7 text-green-500" />
            </div>
          </div>
          {/* text */}
          <div className="w-full flex flex-col gap-1">
            <h1 className="text-center text-2xl font-semibold">Successful</h1>
            <p className="text-center text-base font-normal text-black-900 text-opacity-60">
              Your request to get a new license is sent to the team.
            </p>
          </div>
          <div className="w-full flex justify-around mt-2">
            <button
              className="text-center py-2 px-6 rounded-full bg-[#E9C3E2] text-base font-space-grotesk"
              onClick={() => {
                setOpenLicenseModal(false);
              }}>
              Close
            </button>
          </div>
        </div>
      </CustomModal>
      <PageHeader title="Licenses" />

      <div className="flex items-center justify-between mt-4">
        {/* Organization Filter */}
        <div className="flex items-center">
          <img
            src="/filter-icon.svg"
            alt="Filter Icon"
            width={20}
            height={20}
            className="mr-2"
          />
          <span className="text-neutral-900 font-medium font-space-grotesk">
            Filter by:
          </span>
          {user?.role === UserRole.Admin && (
            <div className="px-2">
              <Dropdown
                options={organizationOptions}
                onChange={setSelectedOrg}
                renderOption={(option) => option.name}
                selectedOption={selectedOrg}
              />
            </div>
          )}
          <div className="px-2">
            <Dropdown
              options={licenseOptions}
              onChange={setLicenseStatusFilter}
              renderOption={(option) => option}
              selectedOption={licenseStatusFilter}
            />
          </div>
        </div>
        {user?.role !== UserRole.Admin && (
          <button
            className="flex items-center gap-1 justify-between py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk hover:ring-pink-200 hover:ring-2"
            type="button"
            onClick={handleRequestLicense}>
            <span className="mr-2">
              {/* Icon container */}
              <img
                src="redirect-arrow.svg"
                alt="Delete"
                width={20}
                height={20}
              />
            </span>
            <span>Request License</span>
          </button>
        )}
      </div>
      <div className="mt-8">
        {/* <LicenseTable licenses={filteredLicenses} /> */}
        {licenses.length === 0 ? (
          <NoProbe />
        ) : (
          <GenericTableWithSorting
            column={colDefs}
            row={rowDefs}
            searchField="organizationName.name"
            searchTerm={membersSearch}
            showPagination={true}
            totalResults={totalResults}
            pageSize={pageSize}
            pageCount={pageCount}
            resetToFirstPage={false}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Licenses;
