export const BASE_URL = import.meta.env.VITE_APP_API_URL;
export const SERVICE_ID_ENV_VAR = import.meta.env.VITE_APP_SERVICE_ID;
export const MATOMO_TAG_MANAGER_CONTAINER_URL = import.meta.env
  .VITE_APP_MATOMO_TAG_MANAGER_CONTAINER_URL;
export const MATOMO_SITE_ID = import.meta.env.VITE_APP_MATOMO_SITE_ID;
export const MATOMO_URL = import.meta.env.VITE_APP_MATOMO_URL;

//Constants for api params
export const SERVICE_ID = `${SERVICE_ID_ENV_VAR}`;
export const SERVICES_API = `${BASE_URL}/services`;

//AUTHENTICATION
export const REGISTRATION_API = `${BASE_URL}/auth/register`;
export const LOGIN_API = `${BASE_URL}/auth/login`;
export const LOGOUT_API = `${BASE_URL}/auth/logout`;
export const FORGOT_PASSWORD_API = `${BASE_URL}/auth/forgot-password`;
export const UPDATE_PASSWORD_API = `${BASE_URL}/auth/update-password`;
export const VERIFY_EMAIL_API = `${BASE_URL}/auth/verify-email`;
export const UPDATE_PASSWORD = `${BASE_URL}/auth/update-password`;

//USER PROFILE
export const PROFILE_INFO_API = `${BASE_URL}/users`;

//PROBES
export const PROBE_DETAILS_API = `${BASE_URL}/devices`;

export const DEVICE_DETAILS_API = `${BASE_URL}/devices/device`;
export const ORGANIZATION_PROBES_API = `${BASE_URL}/devices/organization`;
export const DEVICE_REGISTRATION_STATUS = `${BASE_URL}/devices`;

//CUSTOMERS
export const CUSTOMER_DETAILS_API = `${BASE_URL}/organizations`;

//ORGANIZATIONS
export const ORGANIZATION_USERS_API = `${BASE_URL}/organizations`;
export const CREATE_ORGANIZATION = `${BASE_URL}/organizations/create`;
export const CHECK_ORGANIZATION = `${BASE_URL}/organizations/check`;

//PLANS
export const GET_PLANS = `${BASE_URL}/services`;

//INVOICES
export const PAYMENT_INVOICE_LIST = `${BASE_URL}/invoices`;
export const PAYMENT_INVOICE_LIST_USERS = `${BASE_URL}/invoices/user/organization`;
export const PAYMENT_INVOICE_DOWNLOAD_USERS = `${BASE_URL}/invoices/user`;

export const GET_LICENSES = `${BASE_URL}/licenses`;
export const GET_ORGANIZATION_LICENSES = `${BASE_URL}/licenses/organization`;
export const ORGANIZATION_INVOICE_API = `${BASE_URL}/invoices/organization`;

export const ORGANIZATION_SUBSCRIPTION_API = `${BASE_URL}/subscriptions/organization`;
export const MODIFY_SUBSCRIPTION_API = `${BASE_URL}/subscriptions/modify`;

//LICENSE
export const DEVICE_LICENSE_DETAILS = `${BASE_URL}/licenses/device`;
export const REASSIGN_LICENSE = `${BASE_URL}/licenses/add`;
export const REMOVE_LICENSE = `${BASE_URL}/licenses/remove`;
export const ADD_LICENSE = `${BASE_URL}/licenses/add`;
export const PLAN_ON_LICENSE = `${BASE_URL}/licenses/plan`;
export const GET_ALL_AVAILABLE_LICENSES = `${BASE_URL}/licenses`;
export const GET_LICENSES_BY_ORG_ID = `${BASE_URL}/licenses/organization`;
export const UPDATE_DEVICE_ON_LICENSE = `${BASE_URL}/licenses/update/device`;

//REPORTS
export const GENERATE_REPORT = `${BASE_URL}/reports/report`;

//MANUFACTURER
export const GET_MANUFACTURER = `${BASE_URL}/manufacturers`;
export const CREATE_IMAGE = `${BASE_URL}/manufacturers/createimg`;
export const CREATE_MANUFACTURER = `${BASE_URL}/manufacturers/create`;

//Devices
export const REGISTER_DEVICE = `${BASE_URL}/devices`;

//Subscriptions
export const CREATE_SUBSCRIPTION = `${BASE_URL}/subscriptions/create`;

// PAYMENT SUBSCRIBE
export const SUBSCRIBE_PAYMENT = `${BASE_URL}/subscriptions/paymentUrl`;
